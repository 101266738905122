import React from 'react';
import style from  './ComingSoon.module.css';


function ComingSoon() {
  return (
    <div className= {style.coming_soon}>
        Coming Soon
    </div>
    );
}

export default ComingSoon;