import React from 'react';
import './About.css';


function About() {
  return (
    <div className="About">
        About page in development
    </div>
    );
}

export default About;